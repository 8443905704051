import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom";
import {
    AvField,
    AvForm,
    AvGroup,
    AvRadio,
    AvRadioGroup,
  } from "availity-reactstrap-validation";
import {Col, Row} from "reactstrap";
import {API_URL} from "../../../constants/apiConstants";
import Request from "../../../util/api.service";

class BlApproved extends Component {

    constructor() {
        super();
        this.state = {
            token: '',
            isLoading: true,
            error_message: '',
            linkExpiry: false,
            blData: null,
            blApprovedCommentAdded:false
        };
    };

    componentDidMount() {
        this.checkBlApproved();
    }

    checkBlApproved = () => {
        let {params} = this.props.match;
        let payload = {
            token: params.token,
        };
        Request.post(`${API_URL}user/bl-approved`, payload)
            .then(response => {
                if (response.data.status === 'success') {
                    this.setState({linkExpiry: false,isLoading:false,blData:response.data.data});
                }else{
                    this.setState({linkExpiry: true,isLoading:false});
                }
            }).catch(error => error);

    };
    

    sendRemark = (event, errors, values) => {
        let {params} = this.props.match;
        let payload = {
            bl_number: this.state.blData.bl_number,
            vendor_id: this.state.blData.vendor_id,
            'remark':values.remarks,
            token:params.token,
            'from':'approved'
        };
        Request.post(`${API_URL}user/add-bl-remark`, payload)
            .then(response => {
                if (response.data.status === 'success') {
                    this.setState({returnForCorrection: true,isLoading:false,blApprovedCommentAdded:true});
                }else{
                this.setState({linkExpiry: true,isLoading:false});
                }
            }).catch(error => error);
    }

render()
{
    return !this.state.isLoading ? (
        <>
        <div className="container-fluid">
            <Row>
                <Col lg="6" sm="6" className="reset-password form-model text-capitalize">
                    {this.state.linkExpiry ?<><h1>Link <span>Expired</span></h1></>:
                    this.state.blApprovedCommentAdded ? <><h1>BL <span>Approved </span></h1><p><h4>BL Number : <span>{this.state.blData.bl_number}</span></h4></p></>:
                     <><h1>BL <span>Approved</span></h1>
                    <p>Kindly make the necessary corrections in the remarks and proceed to submit the First Print.</p>
                    <Row>
                      <Col lg="6" sm="6">
                        <h5>Bl Number : <span>{ this.state.blData.bl_number}</span></h5>
                      </Col>
                      <Col lg="6" sm="6">
                        <h5>Shipment Ref Number : <span>{ this.state.blData.book_id}</span></h5>  
                      </Col>
                      <Col lg="6" sm="6">
                        <h5>POL : <span>{ this.state.blData.pol}</span></h5>  
                      </Col>
                      <Col lg="6" sm="6">
                        <h5>POD : <span>{ this.state.blData.pod}</span></h5>  
                      </Col>
                      <Col lg="6" sm="6">
                        <h5>Vessel : <span>{ this.state.blData.vessel}</span></h5>  
                      </Col>
                      <Col lg="6" sm="6">
                        <h5>Voyage : <span>{ this.state.blData.voyage}</span></h5>  
                      </Col>
                      <Col lg="6" sm="6">
                        <h5>Shipline : <span>{ this.state.blData.shipline}</span></h5>  
                      </Col>
                      <Col lg="6" sm="6">
                        <h5>QTY & Container Type : <span>{ this.state.blData.bl_count } & {this.state.blData.container_type}</span></h5>  
                      </Col>
                    </Row>
                    <p>
                    <AvForm onSubmit={this.sendRemark}>
                    <div className="col-lg-12 col-sm-12 text-box">
                      <AvGroup className="error-t-negative remark">
                        <label>Remarks (Max 1500 characters allowed)</label>
                        <span className="required">*</span>
                        <AvField
                          name="remarks"
                          id="remarks"
                          type="textarea"
                          placeholder="BL Remark"
                          value={""}
                          validate={{
                            maxLength: {
                              value: 1500,
                              errorMessage:
                                "You have reached your maximum limit of characters allowed",
                            },
                          }}
                        />
                      </AvGroup>
                    </div>

                        <div className="search-btn-Reset ">
                            <button type='submit' className="btn primary-btn">Send Remark</button>
                        </div>
                        </AvForm>
                    </p>
                    </>}
                    
                </Col>
            </Row>
            
        </div>
        </>
        ):(
        <Fragment />
        )
    
}
}


export default BlApproved;