import React, {Component} from "react";
import {Col} from "reactstrap";
import {withRouter} from "react-router-dom";
import {bookingStatus} from "../../../constants/bookingStatus";
import {connect} from "react-redux";
import {fetchBookings, refreshBookings} from "../../../redux/bookings/actions";
import HistoryCard from "../../../components/booking/HistoryCard";
import collect from "collect.js";
import Select from 'react-select';
import BookingRequest from "../../../util/booking.service";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Request from "../../../util/api.service";
import {API_URL} from "../../../constants/apiConstants";

class SideNav extends Component {

    state = {
        is_loading: true,
        bookingStatusOptions: [],
        selectedBookingStatus: [],
        booking_id_search: null,
        bookings: [],
        bookingStatusArray: [],
        bookingData: {
            data: [],
            count: 0,
            records: 10,
            loading: true,
            /*filters: {
                booking_id: null,
                status: []
            },*/
            sorted: []
        },
        filters: {
            booking_id: null,
            status: []
        },
        originalBookings: [],
        hasMore: true
    };

    componentDidMount() {
        /*if (this.props.bookings.loaded || this.props.bookings.is_loading) {
            this.props.refreshBookings();
        }*/

        this.getPaginatedBookingData();
        // this.getBookingDetails();
    };
    getPaginatedBookingData = () => {
        let bookings = this.state.bookingData;
        /*let filters = JSON.stringify(bookings.filters);
        bookings.filters = filters;*/
        Request.post(API_URL + '/user/booking/listing', bookings).then(response => {
            let bookingData = this.state.bookingData;
            let bookings = bookingData.data;
            let hasMore = true;
            if (response.data.data.length < bookingData.records) {
                hasMore = false
            }
            let bookingStatusOptions = [];
            if (response.data.booking_status) {
                for (let status in response.data.booking_status) {
                    bookingStatusOptions.push({
                        label: bookingStatus[status] + '[' + response.data.booking_status[status] + ']',
                        value: status,
                        key: status
                    });
                }
            }
            for (let booking of response.data.data) {
                booking['booking_status_name'] = (typeof bookingStatus[booking['booking_status']] !== 'undefined') ? bookingStatus[booking['booking_status']] : '';
                //  booking['cro_details'] = (booking.cro_details) ? JSON.parse(booking.cro_details) : false;
                booking['cut_off_date'] = booking['cut_off_date'] ? moment(booking['cut_off_date']) : null;
                booking['etd_pol'] = booking['etd_pol'] ? moment(booking['etd_pol']) :  null;
                booking['eta_pod'] = booking['eta_pod'] ? moment(booking['eta_pod']) : null
                bookings.push(booking);
            }
            bookingData.data = bookings;
            bookingData.count = parseInt(response.data.data.length) + parseInt(bookingData.count);
            this.setState({
                bookings,
                originalBookings: bookings,
                is_loading: false,
                bookingData,
                hasMore,
                bookingStatusOptions
            });

        }).catch(error => error);

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        /* if (prevProps.bookings.list.length !== this.props.bookings.list.length) {
             this.setState({
                 bookings: this.props.bookings,
                 originalBookings: this.props.bookings.list
             });
             this.updateBookingStatusFilterOptions(this.state.bookings);
         }*/
    };

    getBookingDetails = () => {
        BookingRequest.history().then(response => {
            let bookings = [];
            for (let booking of response.data.data) {
                booking['booking_status_name'] = (typeof bookingStatus[booking['booking_status']] !== 'undefined') ? bookingStatus[booking['booking_status']] : '';
                //booking['cro_details'] = (booking && booking.cro_details) ? JSON.parse(booking.cro_details) : false;
                booking['cut_off_date'] = booking['cut_off_date'] ? moment(booking['cut_off_date']) : null;
                booking['etd_pol'] = booking['etd_pol'] ? moment(booking['etd_pol']) : null;
                booking['eta_pod'] = booking['eta_pod'] ? moment(booking['eta_pod']) : null
                bookings.push(booking);
            }

            this.setState({bookings, originalBookings: bookings, is_loading: false});
        }).then(error => error);
    };

    updateBookingStatusFilterOptions = (bookings) => {
        let bookingStatusOptions = [];
        let bookingsCollection = collect(bookings);

        for (let value in bookingStatus) {
            /* booking_status 8(User Rejected), 9(User Approval Pending) to be excluded */
            if (parseInt(value) === 8 || parseInt(value) === 9) {
                continue;
            }

            /* get counts of bookings against each booking_status */
            let count = bookingsCollection.where('booking_status', parseInt(value)).count();
            let label = bookingStatus[value] + ' [' + count + ']';
            bookingStatusOptions.push({value, label, count});
        }
        /* sort alphabetically */
        bookingStatusOptions = collect(bookingStatusOptions).where('count', '!=', 0).sortBy('label').all();
        this.setState({bookingStatusOptions});
    };

    filterByBookingStatus = (value) => {
        let selectedBookingStatus = value;
        let statusArray = [];
        if (selectedBookingStatus && selectedBookingStatus.length) {
            statusArray = selectedBookingStatus.map(status => parseInt(status.value));
        }
        let filters = this.state.filters;
        filters.status = statusArray;
        
        let bookingData = this.state.bookingData;
        bookingData.filters = JSON.stringify(filters);
        bookingData.count = 0;
        bookingData.data = [];

        this.setState({
            filters,
            bookingData,
            selectedBookingStatus: selectedBookingStatus
        }, this.getPaginatedBookingData);
    };

    searchByBookingId = (event) => {

        let bookingData = this.state.bookingData;
        let filters = this.state.filters;
        filters.booking_id = event.target.value;
        bookingData.filters = JSON.stringify(filters);
        bookingData.count = 0;
        bookingData.data = [];
        this.setState({bookingData, filters}, this.getPaginatedBookingData);
    };

    fetchMoreData = () => {
        this.getPaginatedBookingData();
    };

    getBookingListHtml = () => {
        if (this.state.is_loading) {
            return (
                <h2 className={"loading-text-small"}>
                    Loading bookings...
                </h2>
            );
        } else if (this.state.bookingData.data.length === 0) {
            return (
                <h2 style={{textAlign: 'center', width: '100%'}}>
                    <br/>
                    Sorry, No result found.
                </h2>
            );
        } else {
            return this.state.bookingData.data.map((booking, index) => {
                return <HistoryCard bookingDetails={booking}
                                    isActive={booking.id === this.props.bookings.activeBooking}
                                    is_active={this.props.bookings.activeBooking === booking.id}
                                    key={index}/>

            });
        }
    };

    render() {

        return (
            <Col lg="4" sm="4">
                <div className="serach-box-booking">
                    <div className="search-input-file-cng">
                        <input type="text" placeholder="Search by Shipment Ref No"
                               onChange={this.searchByBookingId}
                               name="booking_id"/>
                        <button type="submit"><i className="fa fa-search"/></button>
                        <Select
                            defaultValue={this.state.selectedBookingStatus}
                            onChange={this.filterByBookingStatus}
                            isMulti
                            placeholder={"Filter by Booking Status"}
                            options={this.state.bookingStatusOptions}
                            className="basic-multi-select round-select"
                            classNamePrefix="select"
                            noOptionsMessage={() => "No record found"}
                        />
                    </div>
                    <InfiniteScroll
                        dataLength={this.state.bookingData.data.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        height={400}
                        loader={ <h2 className={"loading-text-small"}>
                            Loading Bookings...
                        </h2>}
                        endMessage={
                            <p style={{textAlign: "center"}}>
                                <b>{this.state.bookingData.data.length ? "Yay! You have seen it all" : ""}</b>
                            </p>
                        }
                    >
                        {this.state.bookingData.data.map((booking, index) => (
                            <HistoryCard bookingDetails={booking}
                                         isActive={booking.id === this.props.bookings.activeBooking}
                                         is_active={this.props.bookings.activeBooking === booking.id}
                                         key={index}/>

                        ))}

                    </InfiniteScroll>

                    {/*<div className="serach-box-booking-full-scroll">
                        {this.getBookingListHtml()}
                    </div>*/}
                </div>
            </Col>
        );
    }
}

const mapStateToProps = ({bookings}) => {
    return {bookings};
};

export default withRouter(
    connect(
        mapStateToProps,
        {fetchBookings, refreshBookings}
    )(SideNav)
);