import React, {Component, Fragment} from "react";
import {AvField, AvGroup} from "availity-reactstrap-validation";
import {REFRIGERATED} from "../../constants/containerTypes";

class ContainerDetailSectionRow extends Component {

    state = {
        netWeightError: null
    }

    componentDidMount() {
        this.setState({
            cargo_weight: this.props.container.cargo_weight,
            cargo_weight_unit: 1,
            tare_weight: this.props.container.tare_weight,
            tare_weight_unit: 1,
            net_weight: this.props.container.net_weight ? this.props.container.net_weight : 0,
            net_weight_unit: 1
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.container.net_weight !== this.props.container.net_weight){
            this.setState({
                cargo_weight: this.props.container.cargo_weight,
                cargo_weight_unit: 1,
                tare_weight: this.props.container.tare_weight,
                tare_weight_unit: 1,
                net_weight: this.props.container.net_weight ? this.props.container.net_weight : 0,
                net_weight_unit: 1
            });
        }
    }

    checkWeightValidation = (name, container_id, event) => {
        if (name === 'cargo_wt_') {
            let netWeightError = null;
            if (parseFloat(event.target.value) * parseFloat(this.state.cargo_weight_unit) < parseFloat(this.state.net_weight) * parseFloat(this.state.net_weight_unit)) {
                netWeightError = "Net Wt must be less than Cargo Wt.";
            }
            this.setState({
                cargo_weight: parseFloat(event.target.value),
                netWeightError
            });

            this.props.getTotalCargoWeight(parseFloat(event.target.value) * parseFloat(this.state.cargo_weight_unit), this.props.container.id);
            this.props.handleInputChangeVersion(event);
        } else if (name === 'cargo_weight_unit_') {
            let netWeightError = null;
            if (parseFloat(this.state.cargo_weight) * parseFloat(event.target.value) < parseFloat(this.state.net_weight) * parseFloat(this.state.net_weight_unit)) {
                netWeightError = "Net Wt must be less than Cargo Wt.";
            }
            this.setState({
                cargo_weight_unit: event.target.value,
                netWeightError
            });
            this.props.getTotalCargoWeight(parseFloat(event.target.value) * parseFloat(this.state.cargo_weight), this.props.container.id);

        } else if (name === 'net_wt_') {
            let netWeightError = null;
            if (parseFloat(this.state.cargo_weight) * parseFloat(this.state.cargo_weight_unit) < parseFloat(event.target.value) * parseFloat(this.state.net_weight_unit)) {
                netWeightError = "Net Wt must be less than Cargo Wt.";
            }
            this.setState({
                net_weight: parseFloat(event.target.value),
                netWeightError
            });

            this.props.getTotalNetWeight(parseFloat(event.target.value) * parseFloat(this.state.net_weight_unit), this.props.container.id);
            this.props.handleInputChangeVersion(event);
        } else if (name === 'net_weight_unit_') {
            let netWeightError = null;
            if (parseFloat(this.state.cargo_weight) * parseFloat(this.state.cargo_weight_unit) < parseFloat(this.state.net_weight) * parseFloat(event.target.value)) {
                netWeightError = "Net Wt must be less than Cargo Wt.";
            }
            this.setState({
                net_weight_unit: event.target.value,
                netWeightError
            });
            this.props.getTotalNetWeight(parseFloat(event.target.value) * parseFloat(this.state.net_weight), this.props.container.id);
        } else if (name === "tare_wt_") {
            this.setState({tare_weight: event.target.value});
            this.props.getTotalTareWeight(parseFloat(event.target.value) * parseFloat(this.state.tare_weight_unit), this.props.container.id);
            this.props.handleInputChangeVersion(event);
        } else if (name === "tare_weight_unit_") {
            this.setState({tare_weight_unit: event.target.value});
            this.props.getTotalTareWeight(parseFloat(this.state.tare_weight) * parseFloat(event.target.value), this.props.container.id);
        }
    };

    render() {
        let sr_no = parseInt(this.props.keyId) + 1;
        return (
            <Fragment>
                <tr key={this.props.keyId}>
                    <td className="tc-line-height">{sr_no}</td>
                    {this.props.container.vgm_done_at ?
                        <td className="tc-line-height up-case">{this.props.container.container_number}</td>
                        :
                        <td>
                            <AvGroup className="error-t-negative">
                                <AvField name={'container_number_' + this.props.container.id}
                                         className={(this.props.container.container_number && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].container_number !== this.props.container.container_number) ? "changed-input up-case" : "up-case"}
                                         id={'container_number_' + this.props.container.id}
                                         type="text"
                                         value={this.props.container.container_number ? this.props.container.container_number : ""}
                                         onChange={this.props.handleInputChangeVersion}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter Container Number'
                                             },
                                             pattern: {
                                                 value: '^\s*([A-Z]{3}[J|U|Z][0-9]{7})$',
                                                 errorMessage: 'Please provide Container Number in a proper format.'
                                             }
                                         }}
                                         ogvalue={(this.props.container.container_number) ? this.props.container.container_number : ""}
                                />
                            </AvGroup>
                        </td>
                    }
                    {parseInt(this.props.container.container_type) === REFRIGERATED ?
                        <td className="tc-line-height">{this.props.container.container_size === "20" ?  "20 RF" : "40 RF HC"}</td>
                        :
                        <td className="tc-line-height">{this.props.container.container_size === "20" ? "20 FT" : (this.props.container.container_size === "40" ? "40 FT" : "40 HC")}</td>
                    }
                    <td>
                        <AvGroup className="error-t-negative">
                            <AvField name={'customs_seal_' + this.props.container.id}
                                     className={(this.props.container.customs_seal && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].customs_seal !== this.props.container.customs_seal) ? "changed-input up-case" : "up-case"}
                                     id={'customs_seal_' + this.props.container.id}
                                     type="text"
                                     value={this.props.container.customs_seal ? this.props.container.customs_seal : ""}
                                     validate={{
                                         maxLength: {
                                             value: 250,
                                             errorMessage: 'You have reached your maximum limit of characters allowed'
                                         }
                                       /*  required: {
                                             value: true,
                                             errorMessage: 'Please enter Custom Seal'
                                         },
                                         pattern: {
                                             value: '^\s*([a-zA-Z]{4}[0-9]{7})$',
                                             errorMessage: 'Please enter valid Custom Seal'
                                         }*/
                                     }}
                                     onChange={this.props.handleInputChangeVersion}
                                     ogvalue={(this.props.container.customs_seal) ? this.props.container.customs_seal : ""}
                            />
                        </AvGroup>
                    </td>
                    <td>
                        <AvGroup className="error-t-negative">
                            <AvField name={'agent_seal_' + this.props.container.id}
                                     className={(this.props.container.agent_seal && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].agent_seal !== this.props.container.agent_seal) ? "changed-input up-case" : "up-case"}
                                     id={'agent_seal_' + this.props.container.id} type="text"
                                     value={this.props.container.agent_seal ? this.props.container.agent_seal : ""}
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please enter Agent Seal'
                                         },
                                         maxLength: {
                                             value: 250,
                                             errorMessage: 'You have reached your maximum limit of characters allowed'
                                         }
                                         /* pattern: {
                                              value: '^\s*([a-zA-Z]{4}[0-9]{7})$',
                                              errorMessage: 'Please enter valid Agent Seal'
                                          }*/
                                     }}
                                     onChange={this.props.handleInputChangeVersion}
                                     ogvalue={this.props.container.agent_seal ? this.props.container.agent_seal : ""}

                            />
                        </AvGroup>
                    </td>
                    <td>
                        <AvGroup className="error-t-negative">
                            <AvField name={'cargo_wt_' + this.props.container.id}
                                     className={(this.props.container.cargo_weight && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].cargo_weight !== this.props.container.cargo_weight) ? "changed-input" : ""}
                                     id={'cargo_wt_' + this.props.container.id}
                                     type="text"
                                     onChange={(event) => this.checkWeightValidation('cargo_wt_', this.props.container.id, event)}
                                     value={this.props.container.cargo_weight}
                                     ogvalue={this.props.container.cargo_weight}/>

                            <AvField name={'cargo_weight_unit_' + this.props.container.id} type="select"
                                     className="cargo_weight_unit"
                                     customprop="cargo_weight_unit"
                                     onChange={(event) => this.checkWeightValidation('cargo_weight_unit_', this.props.container.id, event)}
                                     id={'cargo_weight_unit_' + this.props.container.id}
                                     value="1">
                                <option value="1">Kgs</option>
                                <option value="1000">Ton</option>
                            </AvField>
                        </AvGroup>
                    </td>

                    <td>
                        <AvGroup className="error-t-negative">
                            <AvField name={'tare_wt_' + this.props.container.id}
                                     className={(this.props.container.tare_weight && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].tare_weight !== this.props.container.tare_weight) ? "changed-input" : ""}
                                     id={'tare_wt_' + this.props.container.id}
                                     type="text"
                                     onChange={(event) => this.checkWeightValidation('tare_wt_', this.props.container.id, event)}
                                     value={this.props.container.tare_weight}
                                     ogvalue={this.props.container.tare_weight}/>

                            <AvField name={'tare_weight_unit_' + this.props.container.id} type="select"
                                     className="tare_weight_unit_"
                                     customprop="tare_weight_unit_"
                                     onChange={(event) => this.checkWeightValidation('tare_weight_unit_', this.props.container.id, event)}
                                     id={'tare_weight_unit_' + this.props.container.id}
                                     value="1">
                                <option value="1">Kgs</option>
                                <option value="1000">Ton</option>
                            </AvField>
                        </AvGroup>
                    </td>

                    <td>
                        <AvGroup className="error-t-negative">
                            <AvField name={'net_wt_' + this.props.container.id} id={'net_wt_' + this.props.container.id}
                                     className={(this.props.container.net_weight && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].net_weight !== this.props.container.net_weight) ? "changed-input" : ""}
                                     type="text"
                                     value={this.props.container.net_weight ? this.props.container.net_weight : ""}
                                     onChange={(event) => this.checkWeightValidation('net_wt_', this.props.container.id, event)}
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please enter Net wt'
                                         },
                                         max: {
                                             value: parseFloat(this.state.cargo_weight) * parseFloat(this.state.cargo_weight_unit),
                                             errorMessage: 'Net Wt must be less than Cargo Wt.'
                                         }
                                     }}
                                     ogvalue={this.props.container.net_weight}
                            />
                            <p>{this.state.netWeightError}</p>
                            <AvField name={'net_weight_unit_' + this.props.container.id} type="select"
                                     className="net_weight_unit"
                                     customprop="net_weight_unit"
                                     onChange={(event) => this.checkWeightValidation('net_weight_unit_', this.props.container.id, event)}
                                     id={'net_weight_unit_' + this.props.container.id}
                                     value="1">
                                <option value="1">Kgs</option>
                                <option value="1000">Ton</option>
                            </AvField>
                        </AvGroup>
                    </td>
                    <td>
                        <AvGroup className="error-t-negative">
                            <AvField name={'volume_' + this.props.container.id} id={'volume_' + this.props.container.id}
                                     className={(this.props.container.volume && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].volume !== this.props.container.volume) ? "changed-input" : ""}
                                     type="number"
                                     value={this.props.container.volume ? this.props.container.volume : ''}
                                     onChange={(event) => this.props.getTotalVolume(event, this.props.container.id)}
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please enter Volume'
                                         },
                                         min: {
                                             value: 0,
                                             errorMessage: 'Value must not be negative.'
                                         }

                                     }}
                                     ogvalue={this.props.container.volume}
                            />
                        </AvGroup>
                    </td>

                    <td className="tc-line-height">
                        <AvGroup className="error-t-negative">
                            <AvField name={'package_type_id_'+this.props.container.id} type="select"
                                     className={(this.props.container.package_type_id && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].package_type_id !== this.props.container.package_type_id) ? "changed-input package_type_id" : "package_type_id"}
                                     customprop="package_type_id"
                                     id={'package_type_id_'+this.props.container.id}
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please Select Package Type'
                                         }
                                     }}
                                     onChange={(event) => this.props.handlePackageChange(event, this.props.container.id)}
                                     value={this.props.container.package_type_id}
                                     ogvalue={this.props.container.package_type_id}
                            >
                                <option value="">Select Package Type</option>
                                {this.props.packageList.map((pkg, key) =>
                                    <option value={pkg.id} data-pkg={pkg.name} key={pkg.id}>{pkg.name}</option>
                                )}
                            </AvField>
                        </AvGroup>
                    </td>



                        <td>
                            <AvGroup className="error-t-negative">
                                <AvField name={'no_of_pkgs_' + this.props.container.id}
                                         className={(this.props.container.no_of_pkgs && this.props.bl_version != null && this.props.bl_version.containers[this.props.container.id] && this.props.bl_version.containers[this.props.container.id].no_of_pkgs !== this.props.container.no_of_pkgs) ? "changed-input" : ""}
                                         id={'no_of_pkgs_' + this.props.container.id}
                                         type="number"
                                         value={this.props.container.no_of_pkgs ? this.props.container.no_of_pkgs : ''}
                                         onChange={(event) => this.props.getTotalPackages(event, this.props.container.id)}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter No of Packages'
                                             },
                                             min: {
                                                 value: 0,
                                                 errorMessage: 'Value must not be negative.'
                                             }

                                         }}
                                         ogvalue={this.props.container.volume}
                                />
                            </AvGroup>
                        </td>

                        <td>
                            <i title="Remove Container" onClick={() => this.props.removeContainer(this.props.container.id, this.props.container.id)}
                               className="fa fa-minus-circle"/>
                        </td>

                </tr>
            </Fragment>
        );
    }

}

export default ContainerDetailSectionRow;
