import React, { Component, Fragment } from "react";
import { AvField, AvGroup } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import CustomFeedback from "../formValidations/CustomFeedback";
import Request from "../../util/api.service";
import { API_URL } from "../../constants/apiConstants";
import { CONSIGNEE } from "../../constants/contactType";

class SBTable extends Component {
  state = {
    jobDate: null,
    jobDateError: null,
    sbDate: null,
    stateList: [],
    consigneeList: [],
    isLoading: true,
  };

  componentDidMount() {
    this.getStateList();
    this.getConsignee();
  }

  getStateList = () => {
    Request.get(API_URL + "state-by-country/96")
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data.data);
          this.setState({ stateList: response.data.data, isLoading: false });
        }
      })
      .catch((error) => error);
  };

  getConsignee = () => {
    Request.get(API_URL + "user/address_book/" + CONSIGNEE)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data.data);
          this.setState({
            consigneeList: response.data.data,
            isLoading: false,
          });
        }
      })
      .catch((error) => error);
  };

  handleChange = (name, error, selected) => {
    let errorName = null;
    this.setState({ [name]: selected, [error]: errorName });
  };

  render() {
    return !this.state.isLoading ? (
      <>
        <div className="new-change-confirm-booking">
          <div className="row">
            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Job Number<span className="required">*</span>
                </label>
                <AvField
                  name="job_number"
                  type="text"
                  className="job_number"
                  id="job_number"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Job Number.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <div className="form-modelnew-inn">
                <label>Job Date</label>
                <DatePicker
                  className=""
                  selected={this.state.jobDate}
                  onChange={(date) =>
                    this.handleChange("jobDate", "jobDateError", date)
                  }
                  timeFormat="HH:mm"
                  dateFormat="d MMMM, yyyy"
                  required={true}
                  minDate={new Date()}
                  timeCaption="Time"
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
              <CustomFeedback>{this.state.jobDateError}</CustomFeedback>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  SB Number<span className="required">*</span>
                </label>
                <AvField
                  name="sb_number"
                  type="text"
                  className="sb_number"
                  id="sb_number"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter SB Number.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <div className="form-modelnew-inn">
                <label>Job Date</label>
                <DatePicker
                  className=""
                  selected={this.state.sbDate}
                  onChange={(date) => this.handleChange("sbDate", "", date)}
                  timeFormat="HH:mm"
                  dateFormat="d MMMM, yyyy"
                  required={true}
                  minDate={new Date()}
                  timeCaption="Time"
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  CHA License Number<span className="required">*</span>
                </label>
                <AvField
                  name="cha_license_no"
                  type="text"
                  className="cha_license_no"
                  id="cha_license_no"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter SB Number.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Importer Exporter Code<span className="required">*</span>
                </label>
                <AvField
                  name="importer_exporter_code"
                  type="text"
                  className="importer_exporter_code"
                  id="importer_exporter_code"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Importer Exporter Code.",
                    },
                  }}
                />
              </AvGroup>
            </div>
            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Branch Sr No of Exporter<span className="required">*</span>
                </label>
                <AvField
                  name="branch_sr_no_exporter"
                  type="text"
                  className="branch_sr_no_exporter"
                  id="branch_sr_no_exporter"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Branch Sr No of Exporter.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Imp. Exp. Name<span className="required">*</span>
                </label>
                <AvField
                  name="imp_exp_name"
                  type="text"
                  className="imp_exp_name"
                  id="imp_exp_name"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Imp. Exp. Name.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Imp. Exp Address1<span className="required">*</span>
                </label>
                <AvField
                  name="imp_exp_address1"
                  type="text"
                  className="imp_exp_address1"
                  id="imp_exp_address1"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Imp. Exp Address1",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Imp. Exp Address2<span className="required">*</span>
                </label>
                <AvField
                  name="imp_exp_address2"
                  type="text"
                  className="imp_exp_address2"
                  id="imp_exp_address2"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Imp. Exp Address2.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Imp. Exp City<span className="required">*</span>
                </label>
                <AvField
                  name="imp_exp_city"
                  type="text"
                  className="imp_exp_city"
                  id="imp_exp_city"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Imp. Exp City.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Imp. Exp. State<span className="required">*</span>
                </label>
                <AvField
                  name="exporter_state"
                  type="select"
                  className="exporter_state"
                  id="exporter_state"
                  value=""
                >
                  {this.state.stateList &&
                    this.state.stateList.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                </AvField>
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Imp. Exp PIN<span className="required">*</span>
                </label>
                <AvField
                  name="imp_exp_pin"
                  type="text"
                  className="imp_exp_pin"
                  id="imp_exp_pin"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Imp. Exp PIN.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Exporter class<span className="required">*</span>
                </label>
                <AvField
                  name="exporter_class"
                  type="select"
                  className="exporter_class"
                  customprop="exporter_class"
                  id="exporter_class"
                  value=""
                >
                  <option value="P">Private</option>
                  <option value="G">Government</option>
                </AvField>
              </AvGroup>
            </div>
            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Type of Exporter<span className="required">*</span>
                </label>
                <AvField
                  name="exporter_type"
                  type="select"
                  className="exporter_type"
                  customprop="exporter_type"
                  id="exporter_type"
                  value=""
                >
                  <option value="R">Merchant Exporter</option>
                  <option value="F">Mfg. Exporter</option>
                </AvField>
              </AvGroup>
            </div>

            <div className="col-lg-3 col-sm-4">
              <AvGroup className="error-t-negative">
                <label>
                  Authorized Dealer Code<span className="required">*</span>
                </label>
                <AvField
                  name="authorized_dealer_code"
                  type="text"
                  className="authorized_dealer_code"
                  id="authorized_dealer_code"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Authorized Dealer Code.",
                    },
                  }}
                />
              </AvGroup>
            </div>

            {/*<div className="col-lg-3 col-sm-3">
                    <AvGroup className="error-t-negative">
                        <label>Imp. Exp. State<span className="required">*</span></label>
                        <AvField name='exporter_state' type="select"
                                 className="exporter_state"
                                 id='exporter_state'
                                 value="">
                            {this.state.stateList && this.state.stateList.map((state) =>
                                <option key={state.id} value={state.id}>{state.name}</option>
                            )}
                        </AvField>
                    </AvGroup>
                </div>*/}
          </div>
        </div>
      </>
    ) : (
      <Fragment />
    );
  }
}

export default SBTable;
