import React, {Component} from "react";
import {Col, Row,} from "reactstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {fetchBookings, refreshBookings} from "../../../redux/bookings/actions";
import {AvField, AvGroup, AvForm} from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import Auth from "../../../util/auth.service";
import moment from "moment";
import Request from "../../../util/api.service";
import {API_URL} from "../../../constants/apiConstants";
import {BULK_ENQUIRY, RATES_UNAVAILABLE} from "../../../constants/enquiryTypes";
import {NotificationManager} from "react-notifications";
import {showAuthModal} from "../../../redux/auth/actions"
import {SIGN_IN_MODE} from "../../../constants/actionTypes";

class Enquiry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            commodity_type: [],
            pod_port: [],
            minDate: new Date(),
            maxDate: new Date(),
            pol_port: [],
            etd_pol: null,
            container_20ft: 0,
            container_40ft: 0,
            container_40hc: 0
        }
    };

    componentDidMount() {
        let minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);
        let maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + 60);
        this.setState({minDate, maxDate});
        this.setState({
            minDate,
            maxDate,
            commodity_type: this.props.commodity_type,
            pol_port: this.props.pol_port,
            pod_port: this.props.pod_port,
            container_type: this.props.container_type,
            etd_pol: this.props.etd_pol,
            container_20ft: this.props.container_20ft,
            container_40ft: this.props.container_40ft,
            container_40hc: this.props.container_40hc,
            isGetQuote: this.props.isGetQuote
        });
        if (this.props.bookings.loaded) {
            this.props.refreshBookings();
        }
    }

    handleChangeDateTime = (date, name) => {
        this.setState({[name]: date});
    };

    onSubmit = (event, errors, values) => {
        if (errors.length === 0) {
            if (!this.props.auth.is_authenticated) {
                this.props.showAuthModal(SIGN_IN_MODE);
            } else {
                let user = Auth.user;
                let data = {
                    ...values,
                    user_company_id: user.company_id,
                    pol: this.state.pol_port.value,
                    pod: this.state.pod_port.value,
                    enquiry_type: (this.state.isGetQuote) ? BULK_ENQUIRY : RATES_UNAVAILABLE,
                    container_type_id: this.state.container_type.value,
                    commodity_type_id: this.state.commodity_type.value,
                    cargo_readiness: moment(this.state.etd_pol).format("YYYY-MM-DD h:mm:ss"),
                    container_20ft: this.props.container_20ft,
                    container_40ft: this.props.container_40ft,
                    container_40hc: this.props.container_40hc,
                    user_id: (Auth.user) ? Auth.user.id : ""
                };

                Request.post(API_URL + '/enquiry', data)
                    .then((response) => {
                        this.setState({
                            isOverdimensionalModal: !this.state.isOverdimensionalModal
                        });
                        if (response.data.status === "success") {
                            this.props.history.push('/enquiry/' + response.data.enquiry_id + '/view');
                            NotificationManager.success('Success message', response.data.message);
                        } else {
                            NotificationManager.error('Error message', response.data.message);
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        }

    };


    render() {
        let user = Auth.user;
        return (
            <div className="enquiry-form-page">
                <AvForm onSubmit={this.onSubmit}>
                    <Row>
                        <Col lg="12" sm="12">
                            <div className="heading-enquiry">
                                <h1>Enquiry form</h1>
                                <p>Sorry, your port pair / commodity rates do not appear on the portal, kindly fill in
                                    the
                                    below details to help us get back with you with the required information.</p>
                            </div>
                        </Col>

                        <Col lg="4" sm="4">
                            <AvGroup className="error-t-negative">
                                <label>Customer Name :</label>
                                <AvField name="customer_name" id="customer_name" type="text"
                                         value={(user) ? user.first_name + ' ' + user.last_name : ""}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter Customer Name'
                                             }
                                         }}/>
                            </AvGroup>

                        </Col>
                        <Col lg="4" sm="4">
                            <AvGroup className="error-t-negative">
                                <label>Tel Number :</label>
                                <AvField name="mobile" id="mobile" type="number"
                                         value={user ? user.mobile : ""}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter Tel Number'
                                             }
                                         }}/>
                            </AvGroup>
                        </Col>
                        <Col lg="4" sm="4">
                            <AvGroup className="error-t-negative">
                                <label>EMail Id:</label>
                                <AvField name="email" id="email" type="text"
                                         value={user ? user.email : ""}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter EMail Id'
                                             }
                                         }}/>
                            </AvGroup>
                        </Col>
                        <Col lg="4" sm="4">
                            <AvGroup className="error-t-negative">
                                <label>From Port:</label>
                                <AvField name="from_port" id="from_port" type="text"
                                         value={(this.state.pol_port) ? this.state.pol_port.label : ''}
                                         readOnly/>
                            </AvGroup>
                        </Col>
                        <Col lg="4" sm="4">
                            <AvGroup className="error-t-negative">
                                <label>To Port:</label>
                                <AvField name="to_port" id="to_port" type="text"
                                         value={(this.state.pod_port) ? this.state.pod_port.label : ''}
                                         readOnly/>
                            </AvGroup>
                        </Col>
                        <Col lg="4" sm="4">
                            <AvGroup className="error-t-negative">
                                <label>Commodity:</label>
                                <AvField name="commodity" id="commodity" type="text"
                                         value={this.state.commodity_type.label}
                                         readOnly/>
                            </AvGroup>

                        </Col>
                        {/*<Col lg="4" sm="4">
                            <AvGroup className="error-t-negative">
                                <label>
                                    Type of equipment
                                </label>
                                <AvField name="equipment_type" id="equipment_type"
                                         type="select"
                                         value={""}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please select Type Of Equipment'
                                             }
                                         }}>
                                    <option value="">Select</option>
                                    <option value="1">Open Top</option>
                                    <option value="2">Flat rack</option>
                                </AvField>
                            </AvGroup>
                        </Col>*/}


                        <Col lg="4" sm="4">
                            <AvGroup className="error-t-negative cargo-weight">
                                <label>Period of Shipment:</label>
                                <AvField name="period_of_shipment" id="period_of_shipment" type="text"
                                         value=""
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter Period of Shipment'
                                             },
                                             min: {
                                                 value: 1,
                                                 errorMessage: 'Period of Shipmentshould not be negative.'
                                             }
                                         }}/>
                            </AvGroup>
                        </Col>

                        <Col lg="4" sm="4">
                            <div className="form-modelnew-inn">
                                <label> Cargo Readiness Date :</label>
                                <DatePicker
                                    name="cargo_readiness"
                                    id="cargo_readiness"
                                    maxDate={this.state.maxDate}
                                    minDate={this.state.minDate}
                                    selected={this.state.etd_pol}
                                    onChange={date => this.handleChangeDateTime(date, "etd_pol")}/>
                            </div>

                        </Col>
                        {this.state.container_20ft > 0 ?
                            <Col lg="4" sm="4">
                                <div className="all-dive-av">
                                    <AvGroup className="error-t-negative cargo-weight">
                                        <label>Cargo Weight per container ( 20 FT ):</label>
                                        <AvField name="cargo_weight_20" id="cargo_weight_20" type="number"
                                                 value={""}
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please enter Cargo Weight per container'
                                                     },
                                                     min: {
                                                         value: 1,
                                                         errorMessage: 'Cargo Weight should not be negative.'
                                                     }
                                                 }}/>
                                    </AvGroup>
                                    <AvGroup className="error-t-negative cargo-weight-value">
                                        <AvField name="kgs" type="text" value="KGS" readOnly={true}/>
                                    </AvGroup>
                                </div>
                            </Col> : null
                        }
                        {this.state.container_40ft > 0 ?
                            <Col lg="4" sm="4">
                                <div className="all-dive-av">
                                    <AvGroup className="error-t-negative cargo-weight">
                                        <label>Cargo Weight per container ( 40 FT ):</label>
                                        <AvField name="cargo_weight_40" id="cargo_weight_40" type="number"
                                                 value={""}
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please enter Cargo Weight per container'
                                                     },
                                                     min: {
                                                         value: 1,
                                                         errorMessage: 'Cargo Weight should not be negative.'
                                                     }
                                                 }}/>
                                    </AvGroup>
                                    <AvGroup className="error-t-negative cargo-weight-value">
                                        <AvField name="kgs" type="text" value="KGS" readOnly={true}/>
                                    </AvGroup>
                                </div>
                            </Col>
                            : null
                        }

                        {this.state.container_40hc > 0 ?
                            <Col lg="4" sm="4">
                                <div className="all-dive-av">
                                    <AvGroup className="error-t-negative cargo-weight">
                                        <label>Cargo Weight per container ( 40 HC ):</label>
                                        <AvField name="cargo_weight_40hc" id="cargo_weight_40hc" type="number"
                                                 value={""}
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please enter Cargo Weight per container'
                                                     },
                                                     min: {
                                                         value: 1,
                                                         errorMessage: 'Cargo Weight should not be negative.'
                                                     }
                                                 }}/>
                                    </AvGroup>
                                    <AvGroup className="error-t-negative cargo-weight-value">
                                        <AvField name="kgs" type="text" value="KGS" readOnly={true}/>
                                    </AvGroup>
                                </div>
                            </Col>
                            : null}
                        <Col lg="12" sm="12">
                            <AvGroup className="error-t-negative">
                                <label>Remarks (if any)</label>
                                <AvField name="remarks" id="remarks" type="textarea"
                                         value={""}
                                />
                            </AvGroup>
                        </Col>
                        <Col lg="12" sm="12">
                            <div className="input-box-div-inn-btn">
                                <button>
                                    Submit Enquiry
                                </button>
                            </div>
                        </Col>

                    </Row>
                </AvForm>
            </div>
        );
    }
}

const mapStateToProps = ({bookings, auth}) => {
    return {bookings, auth};
};

export default withRouter(
    connect(
        mapStateToProps,
        {fetchBookings, showAuthModal, refreshBookings}
    )(Enquiry)
);