import React, { useEffect, useRef, useState } from 'react';

import {Button, message, Tooltip, Upload} from 'antd';
import axios from 'axios';
import {API_URL} from "../../constants/apiConstants";
import { UploadOutlined } from '@ant-design/icons';


export default function AntdFileUpload({
                                       label,
                                       filePath,
                                       allowedFileTypes = ['jpg', 'png', 'pdf', 'excel', 'jpeg'],
                                       maxFileSize = 1,
                                       maxNoOfFile = 1,
                                       fileList = [],
                                       disabled = false,
                                       isAttachmentUi = false,
                                       isPreview = false,
                                       uiType = 'picture',
                                       updateParentState,
                                           setIsLoading,
                                           customClass
                                   }) {

    const [selectedFileList, setFileInfo] = useState(fileList);
    const [uploadedFileName, setUploadedFileName] = useState('');

    const [uploadingFile, setUploadingFile] = useState(false);
    const [progress, setProgress] = useState(0);
    // const dispatch = useDispatch();
    const uploadFileRef = useRef(fileList);

    const [maxFileUploaded, setMaxFileUploaded] = useState(false);
    const reduxDefaultFileListRef = uploadFileRef;
    const customRequest = async (options) => {
        setUploadingFile(true);
        setIsLoading(true);

        const {file, onSuccess, onError} = options;
        const formData = new FormData();
        formData.append('file_uploaded', file);
        // formData.append('media_type', 'image');

        const token = localStorage.getItem('auth_token');
        let config = {
            method: 'post',
            url: `${API_URL}user-register-file`,
            headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Token ${token}`},
            data: formData,
            onUploadProgress: ({ loaded, total }) => {
                const progress = Math.round((loaded * 100) / total);
                setProgress(progress);
            }
        };

        axios.request(config)
            .then((fileUploadResponse) => {
                let response = fileUploadResponse.data;
                if (response.status === 'success') {
                    setUploadedFileName(response.data);
                    let modifiedData = [...selectedFileList].map((row) => {
                        if (row.uid == file.uid) {
                            row.s3_file = response.data;
                        }
                        return row;
                    });
                    setFileInfo(modifiedData);
                    setUploadingFile(false);
                    updateParentState(modifiedData);
                    // setIsLoading(false);
                    onSuccess(true);
                } else {
                    setUploadedFileName('');
                    setUploadingFile(false);
                    message.error(response?.message);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                    setUploadedFileName('');
                setUploadingFile(false);
                // onError('Could not upload file');
                message.error('Could not upload file');
            });
    };

    const showErrorNotification = (errorName) => {
        // Check if a notification with the same name is already active
        const existingToast = message.isActive(errorName);

        if (existingToast) {
            // If it exists, update the content
            message.update(existingToast, {
                render: errorName,
                type: message.TYPE.ERROR,
            });
        } else {
            // If it doesn't exist, create a new notification
            message.error(errorName, { toastId: errorName });
        }
    };

    const beforeUpload = (file, data,idnex,asd) => {
        let valid = true;
        const fileTypeArr = file.type.split('/');
        const fileType = fileTypeArr.pop().split('.');
        const ext = fileType.pop().toString();
        const isLt2M = file.size / 1024 / 1024 < maxFileSize;

        const allTypes = {
            excel: [
                'excel',
                'xls',
                'xlsx',
                'xlsm',
                'xlsb',
                'xlw',
                'sheet',
                'spreadsheet',
                'ms-excel',
                'vnd.ms-excel',
                'x-excel',
            ],
            pdf: ['pdf'],
            ppt: [
                'mspowerpoint',
                'powerpoint',
                'ms-powerpoint',
                'x-mspowerpoint',
                'presentation',
                'ppt',
                'pptx',
                'ppsx',
                'slideshow',
            ],
            doc: ['ms-doc', 'doc', 'msword', 'document', 'docx'],
            png: ['png'],
            jpg: ['jpg'],
            jpeg: ['jpeg'],
        };
        const isNotValidExt =
            Object.entries(allTypes).filter(
                (row) =>
                    row[1].includes(ext) === true && allowedFileTypes.includes(row[0])
            ).length === 0;

        if (selectedFileList.length > maxNoOfFile) {
            valid = false;
            const msg = `Max ${maxNoOfFile} file allowed.`;
            message.error(msg);
        } else if (data.length + uploadFileRef.current.length > maxNoOfFile) {
            valid = false;
            const msg = `Max ${maxNoOfFile} file allowed.`;
            message.error(msg);
        } else if (uploadFileRef.current.length >= maxNoOfFile && data.length > 0) {
            valid = false;
            const msg = `Max ${maxNoOfFile} file allowed.`;
            message.error(msg);
        } else if (isNotValidExt) {
            valid = false;
            const msg = `Invalid file format. Please upload ${allowedFileTypes.join(
                '/'
            )} type`;
            message.error(msg);
        } else if (!isLt2M) {
            valid = false;
            message.error(`file must smaller than ${maxFileSize}MB!`);
        }
        if (data.length == maxNoOfFile && valid) {
            setMaxFileUploaded(true);
        }

        return valid;
    };
    let divCss =
        (isAttachmentUi ? '' : `antd-upload-btn-css`) +
        (isPreview ? ' hide-antd-delete-btn' : '');

    divCss = uiType === 'picture-card' ? 'antd-picture-card-div' : divCss;
    // divCss = label === 'dc_group_icon' ? 'flat_antd_upload_list' : divCss;

    useEffect(() => {
        if (fileList.length === 0) {
            reduxDefaultFileListRef.current = [];
            setMaxFileUploaded(false);
        }
    }, [fileList]);

    const onChange = (info) => {
        let fileInfoClone =[...selectedFileList];
        let nextState = {};
        switch (info.file.status) {
            case "uploading":
                fileInfoClone.push(info.file);
            setFileInfo(fileInfoClone);
                break;
            case "done":
                let fileLists = fileInfoClone;
                let modifiedData = (fileLists || []).map((row) => {
                    if(row.uid === info.file.uid){
                        row = info.file;
                        row.s3_file = uploadedFileName;
                    }
                    return row;
                });
                setUploadedFileName('');
                setFileInfo(modifiedData);
                break;
            default:
                let filteredData = (fileInfoClone || []).filter((row) => row.uid !== info.file.uid);
                fileInfoClone = filteredData;
                setFileInfo(fileInfoClone);
        }
    };
    const onRemove = (e) => {
        // console.log('wwww',e,selectedFileList);
        setFileInfo([]);
        // setIsLoading(false);
        setMaxFileUploaded(false);
        updateParentState(selectedFileList.filter((row)=>row.uid !== e.uid));
    };

    return (
        <div className={uiType === '' ? '' : divCss}>
            <Upload
                multiple={maxNoOfFile > 1}
                fileList={selectedFileList}
                customRequest={customRequest}
                onChange={onChange}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
                progress={{
                    success: {percent: progress},
                    format: () => `${parseInt(progress)}%`,
                }}
            >
                {!isPreview ? (
                    // You can upload up to two image files, each not exceeding 2 MB.
                    <Tooltip
                        placement="top"
                        title={`Max ${maxNoOfFile} ${allowedFileTypes.join('/')} file can be upload with ${maxFileSize} mb each only`}
                    >
                        <Button
                            disabled={
                                disabled ||
                                reduxDefaultFileListRef.current.length >= maxNoOfFile ||
                                maxFileUploaded
                            }
                        >
                            {uiType === '' ? (
                                <UploadOutlined />
                            ) : isAttachmentUi ? (
                                <span>
                    {' '}'++++'
                                    {/*<AttachmentIcon />{' '}*/}
                  </span>
                            ) : (
                                '+'
                                // <PlusTabIcon />
                            )}
                            {reduxDefaultFileListRef.current.length >= maxNoOfFile
                                ? 'Uploaded'
                                : isAttachmentUi
                                    ? 'Add Attachments'
                                    : 'Upload File'}
                        </Button>
                        {/* </Spin> */}
                    </Tooltip>
                ) : (
                    ''
                )}
            </Upload>
        </div>
    );
}
