import React, {Component} from 'react';
import {Col, Container, Row, Button, Popover, PopoverBody} from "reactstrap";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Request from "../../util/api.service";
import {API_URL} from "../../constants/apiConstants";
import {isEmptyNullUndefined, weekDays} from "../../util/util.service";
import Search from "../../util/search.service";
import moment from "moment";
import {containerTypes, REFRIGERATED} from "../../constants/containerTypes";
import collect from "collect.js";
import HoverView from '../../assets/images/hover-view.png';
import containersize20 from '../../assets/images/container-size20.png';
import containersize40 from '../../assets/images/container-size40.png';
import containersize40hc from '../../assets/images/container-size40hc.png';

class ModifySearch extends Component {

    state = {
        popover1Open: false,
        popover2Open: false,
        popover3Open: false,
        commodity_types: [],
        pol_ports: [],
        pod_ports: [],
        pol_port: null,
        pod_port: null,
        commodity_type: null,
        container_20ft: 0,
        container_40ft: 0,
        container_40hc: 0,
        total_containers: 0,
        etd_pol: null,
        minDate: new Date(),
        error_message: '',
        isEditable: 0, // 0-modify, 1-search
        inputChanged: false,
        isContainerRefrigerated: false,
        container_type: null,
        container_types: [],
        modifyError: null,
        polPlaceholder : null,
        podPlaceholder : null
    };

    componentDidMount() {
        let minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);
        let maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + 60);
        this.setState({
            container_20ft: this.props.container_20ft,
            container_40ft: this.props.container_40ft,
            container_40hc: this.props.container_40hc,
            minDate,
            maxDate,
            etd_pol: this.props.queryParams.etd_pol
        });
        new Promise((resolve, reject) => {
            Request.get(`${API_URL}master/commodity-type`)
                .then(response => {
                    let isContainerRefrigerated = false;
                    if (response.data.status === 'success') {
                        let commodity_types = [],
                            container_types = [],
                            selected_commodity_type = null;
                        let selected_container_type = null;

                        let commodityList = collect(response.data.data);
                        commodityList = commodityList.where('container_type_id', parseInt(this.props.queryParams.container_type));
                        commodityList = commodityList.toArray();

                        /*  Commodity Types list according to Container Type */

                        for (let commodity_type of commodityList) {
                            let commodity = {
                                label: commodity_type.title,
                                value: commodity_type.id,
                                key: commodity_type.id,
                                container_type_id: commodity_type.container_type_id,
                                container_type: commodity_type.container_type
                            };

                            if (parseInt(commodity_type.id) === parseInt(this.props.queryParams.commodity_type_id)) {
                                selected_commodity_type = commodity;
                                if (selected_commodity_type.container_type_id === 2) {
                                    isContainerRefrigerated = true;
                                }
                            }
                            commodity_types.push(commodity);
                        }

                        /* Container Type list */

                        for (let container in containerTypes) {
                            //if(parseInt(container) !== OVERDIMENSIONAL){
                                let containerType = {
                                    label: containerTypes[container],
                                    value: container,
                                    key: container
                                };
                                if (parseInt(containerType.value) === parseInt(this.props.queryParams.container_type)) {
                                    selected_container_type = containerType;
                                }
                                container_types.push(containerType);
                         //   }

                        }


                        this.setState({
                            commodity_types,
                            originalCommodityList: response.data.data,
                            commodity_type: selected_commodity_type,
                            isContainerRefrigerated,
                            container_type: selected_container_type,
                            container_types
                        });
                        this.props.setParentState('commodity_type', selected_commodity_type);
                        this.props.setParentState('container_type', selected_container_type);
                    }
                }).catch(err => err);

            Request.post(`${API_URL}ports`, {port_attributes: JSON.stringify({un_code: this.props.queryParams['pol_un_code']})})
                .then(response => {
                    if (response.data.status === 'success') {
                        let port = response.data.data[0];
                        let pol_port = {
                            label: port.un_code + ' - ' + port.port_name,
                            un_code: port.un_code,
                            value: port.id,
                            key: port.id,
                            country: port.country.name
                        };
                        let pol_ports = [pol_port];
                        this.setState({pol_port, pol_ports});
                        this.props.setParentState('pol_port', pol_port);

                        Request.post(`${API_URL}ports`, {port_attributes: JSON.stringify({un_code: this.props.queryParams['pod_un_code']})})
                            .then(response => {
                                if (response.data.status === 'success') {
                                    let port = response.data.data[0];
                                    let pod_port = {
                                        label: port.un_code + ' - ' + port.port_name,
                                        un_code: port.un_code,
                                        value: port.id,
                                        key: port.id,
                                        country: port.country.name
                                    };
                                    let pod_ports = [pod_port];
                                    this.setState({pod_port, pod_ports});
                                    this.props.setParentState('pod_port', pod_port);
                                    resolve();
                                }
                            });
                    }
                });
        }).then(() => {
            this.props.getSearchResults();
        }).catch(() => {
        });
    }

    toggleMode = () => {
        if (this.state.commodity_type === null) {
            this.setState({modifyError: 'Select Commodity'});
        } else {
            const isEditable = (!this.state.isEditable);
            const {inputChanged} = this.state;
            this.setState({isEditable});
            if (!isEditable && inputChanged) {
                this.props.getSearchResults();
                this.setState({inputChanged: false});
            }
        }

    };

    handleInputChange = (newValue, type, polPlaceholder) => {
        if (newValue) {
            this.setState({[polPlaceholder]: 'Loading....'});
            const inputValue = newValue.replace(/\s\s+/g, ' ');
            Search.searchPort(inputValue)
                .then((response) => {
                    let data = response.data;
                    if (data.status === 'success') {
                        let selection_list = [], port_list = data.data;
                        for (let port of port_list) {
                            let obj = {
                                label: port.un_code + ' - ' + port.port_name,
                                un_code: port.un_code,
                                value: port.id,
                                key: port.id,
                                country: port.country.name
                            };
                            selection_list.push(obj);
                        }
                        this.setState({[type + '_ports']: selection_list,
                            [polPlaceholder]: (port_list.length === 0 ? 'No record found' : '')  });
                        // Storage.setItem(type + '_search', inputValue);
                    }else{
                        this.setState({[polPlaceholder]: 'No record found'});
                    }
                }).catch(err => err);

            return inputValue;
        }
    };

    handleChange = (selected, type) => {
        let commodity_types = this.state.commodity_types;
        let commodity_type = this.state.commodity_type;
        if (type === 'container_type') {
            let originalCommodityList = collect(this.state.originalCommodityList);
            originalCommodityList = originalCommodityList.where('container_type_id', parseInt(selected.value));
            originalCommodityList = originalCommodityList.toArray();
            commodity_types = [];
            for (let commodity of originalCommodityList) {
                commodity_types.push({
                    label: commodity.title,
                    value: commodity.id,
                    key: commodity.id,
                    container_type_id: commodity.container_type_id
                });
            }
            commodity_type = null;
        }
        let isContainerRefrigerated = this.state.isContainerRefrigerated;
        if (selected.container_type_id) {
            isContainerRefrigerated = selected.container_type_id === REFRIGERATED;
        }
        this.setState({
            commodity_types,
            commodity_type,
            [type]: selected,
            inputChanged: true,
            isContainerRefrigerated,
            modifyError: null
        });
        this.props.setParentState(type, selected);
    };


    increment = (size) => {
        let container_count = this.state['container_' + size];
        let total_containers = this.state.total_containers;
        if (container_count < 10) {
            total_containers++;
            this.setState({['container_' + size]: ++container_count, total_containers, inputChanged: true});
            this.props.setParentState('container_' + size, container_count);
        }
    };

    decrement = (size) => {
        let container_count = this.state['container_' + size];
        let total_containers = this.state.total_containers;
        if (container_count > 0) {
            total_containers--;
            this.setState({['container_' + size]: --container_count, total_containers, inputChanged: true});
            this.props.setParentState('container_' + size, container_count);
        }
    };
    toggle = (popover) => {
        this.setState({
            [popover]: !this.state[popover]
        });
    }

    render() {
        return (this.state.pod_port && this.state.pod_port.label) ? (
            <Container>
                <Row>
                    <Col sm="12" lg="12">
                        <div className="search-full">
                            <div className="search">

                                <ul>
                                    <li>
                                        <p>Origin Port</p>
                                        {this.state.isEditable ?
                                            <div className="input-tab">
                                                <div className="select select-full">
                                                    <Select
                                                        className="search-select"
                                                        value={this.state.pol_port}
                                                        options={this.state.pol_ports}
                                                        noOptionsMessage={() => "No record found"}
                                                        placeholder={isEmptyNullUndefined(this.state.polPlaceholder) ? this.state.polPlaceholder : 'Select port'}
                                                        onChange={(selected) => this.handleChange(selected, 'pol_port')}
                                                        onInputChange={(inputValue) => this.handleInputChange(inputValue, 'pol','polPlaceholder')}
                                                    />
                                                </div>
                                            </div> :
                                            <span className="span-select">{this.state.pol_port.label}</span>
                                        }
                                        <span
                                            className={this.state.isEditable ? 'search-port-country' : 'modify-port-country'}>{this.state.pol_port ? this.state.pol_port.country : ''}</span>
                                    </li>
                                    <li>
                                        <p>Destination Port</p>
                                        {this.state.isEditable ?
                                            <div className="input-tab">
                                                <div className="select select-full">
                                                    <Select
                                                        className="search-select"
                                                        value={this.state.pod_port}
                                                        options={this.state.pod_ports}
                                                        noOptionsMessage={() => "No record found"}
                                                        placeholder={isEmptyNullUndefined(this.state.podPlaceholder) ? this.state.podPlaceholder : 'Select port'}
                                                        // isDisabled={!this.state.isEditable}
                                                        onChange={(selected) => this.handleChange(selected, 'pod_port')}
                                                        onInputChange={(inputValue) => this.handleInputChange(inputValue, 'pod','podPlaceholder')}
                                                    />
                                                </div>
                                            </div> :
                                            <span className="span-select">{this.state.pod_port.label}</span>
                                        }
                                        <span
                                            className={this.state.isEditable ? 'search-port-country' : 'modify-port-country'}>{this.state.pod_port ? this.state.pod_port.country : ''}</span>
                                    </li>
                                    <li>
                                        <p>Container Type</p>
                                        {this.state.isEditable ?
                                            <div className="input-tab">
                                                <div className="select select-full">
                                                    <Select
                                                        className="search-select"
                                                        value={this.state.container_type}
                                                        options={this.state.container_types}
                                                        // isDisabled={!this.state.isEditable}
                                                        noOptionsMessage={() => "No record found"}
                                                        onChange={(selected) => this.handleChange(selected, 'container_type')}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <span className="span-select">{this.state.container_type.label}</span>
                                        }
                                        <span
                                            className={this.state.isEditable ? 'search-port-country' : 'modify-port-country'}>{this.state.commodity_type ? this.state.commodity_type.container_type : ''}</span>
                                    </li>

                                    <li>
                                        <p>Commodity</p>
                                        {this.state.isEditable ?
                                            <div className="input-tab">
                                                <div className="select select-full">
                                                    <Select
                                                        className="search-select"
                                                        value={this.state.commodity_type}
                                                        options={this.state.commodity_types}
                                                        noOptionsMessage={() => "No record found"}
                                                        isDisabled={false}
                                                        onChange={(selected) => this.handleChange(selected, 'commodity_type')}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <span
                                                className="span-select">{(this.state.commodity_type) ? this.state.commodity_type.label : ""}</span>

                                        }
                                        <span
                                            className={this.state.isEditable ? 'search-port-country' : 'modify-port-country'}>{this.state.commodity_type ? this.state.commodity_type.container_type : ''}</span>
                                    </li>
                                    <li>
                                        <p>Cargo Rediness Date</p>
                                        {this.state.isEditable ?
                                            <div className="input-group input-cln">
                                                <div className="date-change">
                                                    <DatePicker
                                                        className={this.state.isEditable ? "search-datepicker" : "modify-datepicker"}
                                                        selected={this.state.etd_pol}
                                                        onChange={(selected) => this.handleChange(selected, 'etd_pol')}
                                                        maxDate={this.state.maxDate}
                                                        minDate={this.state.minDate}
                                                        disabled={!this.state.isEditable}
                                                        onKeyDown={e => e.preventDefault()}
                                                        dateFormat="dd/MM/yyyy"
                                                        clearIcon={null}
                                                        calendarIcon={null}/>

                                                </div>
                                            </div>
                                            : <span
                                                className="span-select">{moment(this.state.etd_pol).format('DD-MM-YYYY')}</span>
                                        }
                                        <span
                                            className={this.state.isEditable ? 'search-port-country' : 'modify-port-country'}>{this.state.etd_pol ? weekDays[this.state.etd_pol.getDay()] : ''}</span>
                                    </li>
                                    <li>
                                        <p>Container Type</p>
                                        <ul className={this.state.isEditable ? 'search-container-type ul-in' : 'modify-container-type ul-in'}>
                                            <li>
                                                <Popover className="container-box" placement="bottom"
                                                         isOpen={this.state.popover3Open} target="Popover3"
                                                         toggle={this.toggle}>
                                                    {/* <PopoverHeader>Freight charges</PopoverHeader> */}
                                                    <PopoverBody>
                                                        <img src={containersize20} className="img-fluid img-container"
                                                             title=" " alt=""/>
                                                        <ul className="new-pop">
                                                            <li><b>L<span>(cm)</span></b><strong>-</strong>
                                                                <span>589.5</span></li>
                                                            <li><b>W<span>(cm)</span></b><strong>-</strong>
                                                                <span> 235.0</span></li>
                                                            <li><b>H<span>(cm)</span></b><strong>-</strong>
                                                                <span>239.2</span></li>
                                                        </ul>
                                                    </PopoverBody>
                                                </Popover>


                                                <p><b>20</b>FT <Button className="new-btn" id="Popover3"
                                                                       onMouseEnter={() => this.toggle('popover3Open')}
                                                                       onMouseLeave={() => this.toggle('popover3Open')}
                                                >
                                                    <img src={HoverView} className="img-fluid" title=" " alt=""/>
                                                </Button></p>
                                                <span className="qty">Qty</span>
                                                <i className="fa fa-times class-serach"/>
                                                <div className="counter-bg">
                                                    <span className="input-group-btn">
                                                        <button type="button"
                                                                onClick={() => this.decrement('20ft')}
                                                                className="btn btn-default btn-number">
                                                            <i className="fa fa-minus" aria-hidden="true"/>
                                                        </button>
                                                    </span>
                                                    <input type="text" name="quant[1]"
                                                           className="form-control input-number"
                                                           value={this.state.container_20ft}
                                                           min="1"
                                                           max="10" readOnly={true}/>
                                                    <span className="input-group-btn">
                                                        <button type="button"
                                                                onClick={() => this.increment('20ft')}
                                                                className="btn btn-default btn-number">
                                                            <i className="fa fa-plus" aria-hidden="true"/>
                                                        </button>
                                                    </span>
                                                </div>
                                            </li>
                                            {!this.state.isContainerRefrigerated ?
                                                <li>
                                                    <Popover className="container-box" placement="bottom"
                                                             isOpen={this.state.popover2Open} target="Popover2"
                                                             toggle={this.toggle}>
                                                        {/* <PopoverHeader>Freight charges</PopoverHeader> */}
                                                        <PopoverBody>
                                                            <img src={containersize40} className="img-fluid" title=" "
                                                                 alt=""/>
                                                            <ul className="new-pop">
                                                                <li><b>L <span>(cm)</span></b><strong>-</strong>
                                                                    <span>1202.9</span></li>
                                                                <li><b>W<span>(cm)</span></b><strong>-</strong>
                                                                    <span> 235.0</span></li>
                                                                <li><b>H<span>(cm)</span></b><strong>-</strong>
                                                                    <span>239.2</span></li>
                                                            </ul>
                                                        </PopoverBody>
                                                    </Popover>

                                                    <p><b>40</b>FT <Button className="new-btn" id="Popover2"
                                                                           onMouseEnter={() => this.toggle('popover2Open')}
                                                                           onMouseLeave={() => this.toggle('popover2Open')}
                                                    >
                                                        <img src={HoverView} className="img-fluid" title=" " alt=""/>
                                                    </Button></p>
                                                    <span className="qty">Qty</span>
                                                    <i className="fa fa-times class-serach"/>
                                                    <div className="counter-bg">
                                                        <span className="input-group-btn">
                                                            <button type="button"
                                                                    onClick={() => this.decrement('40ft')}
                                                                    className="btn btn-default btn-number">
                                                                <i className="fa fa-minus" aria-hidden="true"/>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="quant[1]"
                                                               className="form-control input-number"
                                                               value={this.state.container_40ft}
                                                               min="1"
                                                               max="10" readOnly={true}/>
                                                        <span className="input-group-btn">
                                                            <button type="button"
                                                                    onClick={() => this.increment('40ft')}
                                                                    className="btn btn-default btn-number">
                                                                <i className="fa fa-plus" aria-hidden="true"/>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </li>
                                                :
                                                null}
                                            <li>
                                                <Popover className="container-box" placement="bottom"
                                                         isOpen={this.state.popover1Open} target="Popover1"
                                                         toggle={this.toggle}>
                                                    {/* <PopoverHeader>Freight charges</PopoverHeader> */}
                                                    <PopoverBody>
                                                        <img src={containersize40hc} className="img-fluid" title=" "
                                                             alt=""/>
                                                        <ul className="new-pop">
                                                            <li><b>L<span>(cm)</span></b> <strong>-</strong>
                                                                <span>1202.9</span></li>
                                                            <li><b>W<span>(cm)</span></b> <strong>-</strong>
                                                                <span> 235.0</span></li>
                                                            <li><b>H<span>(cm)</span></b> <strong>-</strong>
                                                                <span>269.7</span></li>
                                                        </ul>
                                                    </PopoverBody>
                                                </Popover>
                                                <p><b>40 </b>HC <Button className="new-btn" id="Popover1"
                                                                        onMouseEnter={() => this.toggle('popover1Open')}
                                                                        onMouseLeave={() => this.toggle('popover1Open')}
                                                >
                                                    <img src={HoverView} className="img-fluid" title=" " alt=""/>
                                                </Button></p>
                                                <span className="qty">Qty</span>
                                                <i className="fa fa-times class-serach"/>
                                                <div className="counter-bg">
                                                    <span className="input-group-btn">
                                                        <button type="button"
                                                                onClick={() => this.decrement('40hc')}
                                                                className="btn btn-default btn-number">
                                                            <i className="fa fa-minus" aria-hidden="true"/>
                                                        </button>
                                                    </span>
                                                    <input type="text" name="quant[1]"
                                                           className="form-control input-number"
                                                           value={this.state.container_40hc}
                                                           min="1"
                                                           max="10" readOnly={true}/>
                                                    <span className="input-group-btn">
                                                        <button type="button"
                                                                onClick={() => this.increment('40hc')}
                                                                className="btn btn-default btn-number">
                                                            <i className="fa fa-plus" aria-hidden="true"/>
                                                        </button>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="modifysearchbtn">
                                        <button className={this.state.isEditable ? 'search-button' : 'modify-button'}
                                                onClick={this.toggleMode}>{this.state.isEditable ? 'Search' : 'Modify'}</button>
                                        <p style={{color: "red"}}>{this.state.modifyError}</p>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        ) : ""
    }
}

export default ModifySearch;